import { FC } from 'react'
import { MediaFieldType, SiteConfiguration, Theme } from '@portfolio/models'
import { keyframes, styled } from '@portfolio/styles'
import {
  CopyButton,
  Container,
  Link,
  Socials,
  PortableText,
} from '@portfolio/components'
import { NextProject } from './components/NextProject'

type Props = {
  footer: SiteConfiguration['footer']
  footerMobileButtonVisible: boolean
  theme: Theme
  nextProject?: {
    name?: string
    path?: string
    image?: MediaFieldType
  }
}

const Wrap = styled('div', {
  backgroundColor: '$darkThemeBackground',
  display: 'grid',
  color: '$white',
  paddingBottom: '$20',
  paddingTop: '$96',

  '@lg': {
    paddingBottom: '$24',
    paddingTop: '$144',
  },
})
const StyledContainer = styled(Container, {
  display: 'grid',
  gap: '$96',
  '@lg': {
    rowGap: '$144',
  },
})

const BottomWrap = styled('div', {
  rowGap: '$40',
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: 'auto auto',
  '@lg': {
    rowGap: '$40',
    justifyItems: 'center',
    gridTemplateColumns: 'repeat(3, 1fr)',
    alignItems: 'center',
  },
})
const JoinUsWrap = styled('div', {
  display: 'grid',
  alignItems: 'center',
  alignSelf: 'center',
  padding: '10px $20',
  borderRadius: '$rMax',
  border: '1px solid $white_02',
  gridColumn: 'span 2',
  gridTemplateColumns: 'auto 1fr auto',
  '@lg': {
    justifySelf: 'center',
    gridRow: 1,
    gridColumn: 2,
  },
})
const JoinUsText = styled('div', {
  projectFont: 'ui04',
  color: '$white_06',
  paddingLeft: '$16',
  paddingRight: '$32',
  '@lg': {
    projectFont: 'body04',
  },
})
const JoinUsButton = styled(Link, {
  display: 'flex',
  margin: '0 auto',
  color: '$white',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$8',
  projectFont: 'body04',

  zIndex: '$content',
  '&:focus-visible': {
    outline: '$focusBlue solid 3px;',
  },
})

const BottomItems = styled('div', {
  display: 'grid',
  gap: '$24',
  gridAutoFlow: 'column',
  projectFont: 'ui03',
  textTransform: 'uppercase',
  color: '$white_06',
  justifyContent: 'space-between',
  whiteSpace: 'nowrap',
  justifySelf: 'start',
  '@lg': {
    gridRow: 1,
    gridColumn: 1,
  },
})
const ContactWrap = styled('div', {
  maxWidth: 400,
  display: 'grid',
  gap: '$32',
  marginLeft: 'auto',
  marginRight: 'auto',
  textAlign: 'center',
  justifySelf: 'center',
})
const Title = styled('div', {
  projectFont: 'display02',
  paddingBottom: '$32',
})
const Text = styled('div', {
  projectFont: 'body03',
})
const Contact = styled('div', {
  projectFont: 'body03',
  display: 'grid',
  gap: '$8',
  justifyContent: 'center',
  alignItems: 'center',
  gridTemplateColumns: 'auto auto',
})

const pulse = keyframes({
  '0%': {
    transform: 'scale(0.95)',
    boxShadow: '0 0 0 0 rgba(27,198,81, 0.7)',
  },
  '70%': {
    transform: 'scale(1)',
    boxShadow: '0 0 0 8px rgba(27,198,81, 0)',
  },
  '100%': {
    transform: 'scale(0.95)',
    boxShadow: '0 0 0 0 rgba(27,198,81,0)',
  },
})
const NextProjectWrap = styled('div', {
  borderRadius: '$r2',
  overflow: 'hidden',
})

const PulseIcon = styled('div', {
  width: '$8',
  height: '$8',
  background: 'rgb(27,198,81)',
  borderRadius: '$rMax',
  boxShadow: '0 0 0 0 rgba(27,198,81, 1)',
  transform: 'scale(1)',
  animation: `${pulse} 1.6s infinite`,
})

export const Footer: FC<Props> = ({ footer, nextProject }) => {
  return (
    <Wrap>
      <StyledContainer>
        <ContactWrap>
          <div>
            <Title>{footer?.info?.title}</Title>
            <Text>{footer?.info?.text}</Text>
          </div>
          <Contact>
            {footer?.info?.contact?.email}
            {footer?.info?.contact?.email && (
              <CopyButton
                copyText={footer?.info?.contact?.email}
                context="Footer"
              />
            )}
          </Contact>
        </ContactWrap>

        {nextProject && (
          <NextProjectWrap>
            <NextProject {...nextProject} />
          </NextProjectWrap>
        )}

        <BottomWrap>
          {footer && footer.joinUs && (
            <JoinUsWrap>
              <PulseIcon />
              <JoinUsText>
                <PortableText
                  appearance="plain"
                  value={footer.joinUs.description}
                />
              </JoinUsText>
              <JoinUsButton
                {...footer.joinUs.ctaLink}
                target={
                  footer.joinUs.ctaLink?.linkType === 'external'
                    ? '_blank'
                    : undefined
                }
              >
                {footer.joinUs.ctaLabel}
              </JoinUsButton>
            </JoinUsWrap>
          )}
          <BottomItems>
            {footer?.bottomInfo &&
              footer?.bottomInfo.map((item, index) => {
                return item.itemType === 'text' ? (
                  <p key={`bottom-text-${index}`}>
                    &#169; {new Date().getFullYear()} {item?.text}
                  </p>
                ) : (
                  <Link
                    appearance="text"
                    {...item.ctaLink}
                    key={`bottom-link-${index}`}
                  >
                    {item.ctaLabel}
                  </Link>
                )
              })}
          </BottomItems>
          <Socials items={footer?.socials?.items} />
        </BottomWrap>
      </StyledContainer>
    </Wrap>
  )
}
