/**
 * Convention for color naming is tailwind-like with a _ suffix describing opacity
 */
export const colors = {
  // greys
  gray100: '#ececec', // derivative of gray500_01 on white bg
  gray200: '#dadada', // derivative of gray500_02 on white bg
  gray300: '#919090', // derivative of gray500_06 on white bg
  gray400: '#6c6b6b', // derivative of gray500_08 on white bg
  gray500: '#474646', // the base gray in the project
  gray500_01: 'rgba(71, 70, 70, 0.1)',
  gray500_02: 'rgba(71, 70, 70, 0.2)',
  gray500_03: 'rgba(71, 70, 70, 0.3)',
  gray500_04: 'rgba(71, 70, 70, 0.4)',
  gray500_06: 'rgba(71, 70, 70, 0.6)',
  gray500_08: 'rgba(71, 70, 70, 0.8)',
  // whites
  white_005: 'rgba(255, 255, 255, 0.05)',
  white_01: 'rgba(255, 255, 255, 0.1)',
  white_02: 'rgba(255, 255, 255, 0.2)',
  white_024: 'rgba(255, 255, 255, 0.24)',
  white_03: 'rgba(255, 255, 255, 0.3)',
  white_04: 'rgba(255, 255, 255, 0.4)',
  white_05: 'rgba(255, 255, 255, 0.5)',
  white_06: 'rgba(255, 255, 255, 0.6)',
  white_07: 'rgba(255, 255, 255, 0.7)',
  white_08: 'rgba(255, 255, 255, 0.8)',
  white_085: 'rgba(255, 255, 255, 0.85)',
  white: '#FFFFFF',

  //blacks
  black: '#000',
  black_005: 'rgba(0,0,0,0.05)',
  black_01: 'rgba(0,0,0,0.1)',
  black_02: 'rgba(0,0,0,0.2)',
  black_03: 'rgba(0,0,0,0.3)',
  black_05: 'rgba(0,0,0,0.5)',
  black_06: 'rgba(0,0,0,0.6)',
  black_08: 'rgba(0,0,0,0.8)',
  //project Specific
  focusBlue: 'rgba(0,113,227,0.5)',
  errorRed: 'rgba(248, 46, 71, 1)',
  socialsGray: '#F2F2F2',
  darkThemeBackground: '#121212',
  techstackCardsBackground: '#212121',
  techstackLinkBlockBackground: '#1B1B1B',
  aboutPageBackground: '#121212',
  hydrogenPageBackground: '#121212',
}

export type Color = keyof typeof colors
